import './foundation';
import 'jquery-ui/ui/widgets/datepicker.js';
import ScrollHint from './scroll-hint';

/**
 * 共有処理
 */

$(function () {

    /**
     * フォーカスで全選択
     */
    $('input:text').on('focus', function () {
        $(this).trigger('select');
    });

    /**
     * 登録・更新処理
     */
    $('#btn_save').on('click', function () {
        $('#frm_save').trigger('submit');
    });

    /**
     * 削除処理
     */
    $('.button-del').on('click', function () {
        if (window.confirm('本当に削除しますか？')) {
            $('#frm_delete').trigger('submit');
        }
    });

    /**
     * リストからの削除処理
     */
    $('.list-del-btn').on('click', function () {
        let item = $(this).data('item-name');
        let del_msg = $(this).data('del-msg');
        if (del_msg == undefined) {
            del_msg = "";
        } else if (del_msg != "") {
            del_msg = del_msg + "\n";
        }
        let msg = del_msg + (item ? '"' + item + '" を削除しますか？' : '本当に削除しますか？');

        if (window.confirm(msg)) {
            let act = $(this).data('action')
            $('#frm_delete').attr('action', act);
            $('#frm_delete').trigger('submit');
        }
    });

    /**
     * FOUC対策：CSSの最後でローディング時のチラつき防止に #wrapper を非表示に
     * しているので、ロード完了時に表示する
     */
    $('#loading').fadeOut(50);
    $('#wrapper').addClass('loaded');

    /**
     * 横スクロール
     */
    new ScrollHint('.js-scrollable', {
        i18n: {
            scrollable: '横スクロール'
        }
    })
});
