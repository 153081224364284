window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery');

    require('foundation-sites');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

// Abide
Foundation.Abide.defaults.patterns['datetime_sp'] = /(?:19|20)[0-9]{2}(-|\/)(?:(?:0[1-9]|1[0-2])(-|\/)(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])(-|\/)(?:30))|(?:(?:0[13578]|1[02])(-|\/)31))\s(0[0-9]|1[0-9]|2[0-3])((:[0-5][0-9])|(:[0-5][0-9]){2})$/;
Foundation.Abide.defaults.patterns['date'] = /(?:19|20)[0-9]{2}(-|\/)(?:(?:0[1-9]|1[0-2])(-|\/)(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])(-|\/)(?:30))|(?:(?:0[13578]|1[02])(-|\/)31))$/;
Foundation.Abide.defaults.patterns['time'] = /^(0[0-9]|1[0-9]|2[0-3])((:[0-5][0-9])|(:[0-5][0-9]){2})$/
Foundation.Abide.defaults.patterns['time_mmss'] = /^([0-5][0-9]|[0-9]):{0,1}([0-5][0-9])$/
Foundation.Abide.defaults.patterns['emails'] = /^([a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+,{0,1})+$/;
Foundation.Abide.defaults.patterns['hiragana_only'] = /^[ぁ-んー・]*$/;
Foundation.Abide.defaults.patterns['katakana_only'] = /^[ァ-ンー・]*$/;
Foundation.Abide.defaults.patterns['numeric'] = /^[0-9]*$/;

function inValidator(
    $el,      /* jQuery element to validate */
    required, /* is the element required according to the `[required]` attribute */
    parent    /* parent of the jQuery element `$el` */
) {
    
    const csv = $('#' + $el.attr('data-csv')).val();
    const needle = $el.val();
    const csvArr = csv.split(",");

    if (!required && (needle == '')) return true;

    return csvArr.includes(needle);
};
Foundation.Abide.defaults.validators['in'] = inValidator;

function requiredIfActiveValidator(
    $el,      /* jQuery element to validate */
    required, /* is the element required according to the `[required]` attribute */
    parent    /* parent of the jQuery element `$el` */
) {
    let disabled = $el.prop('disabled');
    let value = $el.val();

    return (value.length > 0) || disabled;
};
Foundation.Abide.defaults.validators['required_if_active'] = requiredIfActiveValidator;

function requiredWithValidator(
    $el,      /* jQuery element to validate */
    required, /* is the element required according to the `[required]` attribute */
    parent    /* parent of the jQuery element `$el` */
) {
    let target = '#' + $el.data('with');
    
    if (($(target).val().length > 0) && ($el.val().length <= 0)) {
        return false;
    }

    return true;
}
Foundation.Abide.defaults.validators['required_with'] = requiredWithValidator;

function uniqueValidator(
    $el,      /* jQuery element to validate */
    required, /* is the element required according to the `[required]` attribute */
    parent    /* parent of the jQuery element `$el` */
) {
    let retval = true;
    const target = $el.data('compared-with');
    const value = $el.val();
    const idx = $el.data('id');

    $('.' + target).each(function (index, item) {
        let id = $(item).data('id');

        if ((value == $(item).val()) && (id != idx )) {
            retval = false;
            return false;
        }
    })

    return retval;
}
Foundation.Abide.defaults.validators['unique'] = uniqueValidator;

function requiredGoaltendingValidator(
  $el,      /* jQuery element to validate */
  required, /* is the element required according to the `[required]` attribute */
  parent    /* parent of the jQuery element `$el` */
) {
    let status = $('#'+$el.attr('data-game-status')).val();
    let period = $el.attr('data-period');
    let check = $el.attr('data-check');

    if (period < 4) {
        if (((check == 'true') || $('#' + check).prop('checked'))
            && (parseInt(status) > parseInt(period))) {
            return ($el.val().length > 0);
        }
    } else {
        if (((check == 'true') || $('#' + check).prop('checked'))
            && ((parseInt(status) == 20) || ((parseInt(status) > 90) && (parseInt(status) < 99))))
        {
            return ($el.val().length > 0);
        }
    }

    return true;
};
Foundation.Abide.defaults.validators['required_goaltending'] = requiredGoaltendingValidator;

// Foundation Init
$(document).foundation(); // initialize foundation
